export default [
    [
        {
            col: 12,
            name: "soli_title",
            value: "",
            type: "text",
            placeholder: "Ex.: Alteração cadastral",
            label: "Tìtulo",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "soli_corpo",
            value: "",
            type: "textarea",
            placeholder: "Ex: Preciso que seja alterado o quadro societário da minha empresa.",
            label: "Solicitação",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "soli_files",
            value: "",
            type: "va-file-upload",
            multiple: true,
            label: "Anexos"
        }
    ]
]