<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md9 lg6 xl6">
      <va-card  class="flex mb-4">
        <va-card-content>
          <h4 class="display-4">Atualizar Outros</h4><br>
          <monta-form :form="form" @complete="(data)=>submitUpdateForm(data,'Outros', this)"/>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import MontaForm from "@/components/form/MontaForm";
import DocumentosNewFormStruct from "./DadosForms";
import {submitUpdateForm} from "./DadosFunctions"
export default {
  name: "SolicitacoesNew",
  components: {MontaForm},
  data() {
    return {
      form: {
        struct: DocumentosNewFormStruct.formDadosOutros
      }
    }
  },
  methods:{
    submitUpdateForm,
  },
}
</script>

<style scoped>

</style>