import {FetchWs} from "@/functions/FetchWs";

export async function submitFormNew(data: any, vue: any){
    const body = new FormData();
    body.append("titulo", data.soli_title);
    body.append("corpo", data.soli_corpo);
    data.soli_files.forEach((file: File)=>body.append("arquivo[]", file))
    const response = await FetchWs('solicitacoes/new', 'POST', body);
    if(response && response.success){
        vue.$router.push('/');
    }
}