<template>
  <div class="dashboard">
    <dashboard-info-block />
    <div class="row row-equal">
      <div class="flex xs12 lg6">
        <dashboard-tabs/>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardInfoBlock from './DashboardInfoBlock'
import DashboardTabs from './DashboardTabs'

export default {
  name: 'dashboard',
  components: {
    DashboardInfoBlock,
    DashboardTabs,
  },
  methods: {
  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
      &__title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
